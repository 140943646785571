
.table{
    color: $slate-gray;
    font-weight: 600;
}

th, .th{
    text-transform: uppercase;
    color: $emerald;
    font-size: 0.75rem;
    font-weight: 100;
    border-bottom: none;
}

.table_row_color:nth-child(odd) {
    background: $alice-blue-2;
}

td{
    font-weight: 300;
    border-bottom: 0.1rem solid $alice-blue;
    a{
        color: $rich-black;
        font-weight: 500;
        text-decoration: none;
    }
    .text-bg-terciary{
        background: #6d7f9b !important;
    }
}

.dark-theme{
    .table{
        color: $material-theme-sys-dark-secondary;
    }
    .table_row_color:nth-child(odd) {
        background: $material-theme-ref-neutral-neutral-27;
    }
    th, .th{
        color: $material-theme-sys-dark-secondary;
        border-bottom: 0.1rem solid fade-out($material-theme-ref-neutral-neutral-24, 0.75);
        &.highlighted{
            background: $material-theme-sys-dark-surface-container-highest;
            color: $material-theme-ref-neutral-neutral-95;
        }
    }
    td{
        color: $material-theme-sys-dark-secondary;
        border-bottom: 0.1rem solid fade-out($material-theme-ref-neutral-neutral-24, 0.75);
        a{
            color: $antiflash-white;
            font-weight: 400;
            text-decoration: none;
        }
    }
}