.timer {
  display: grid;
  grid-auto-flow: column;
  gap: 10px;
}

.timer_btn {
  display: grid;
  grid-auto-flow: column;
  gap: 5px;
}

.timer_detial {
  width: max-content;
  padding: 1em;
  border-radius: 10px;
  background: #dcdcdc;
}

.dark-theme {
  .timer_detial {
    background: $material-theme-ref-neutral-neutral-30;
    color: white;
  }
}