$withList: (50, 100, 150, 200, 300);

@each $with in $withList {
  .width-#{$with} {
    width: $with * 1px;
  }
}

$withPercentList: (100);

@each $with in $withPercentList {
  .width-percent-#{$with} {
    width: $with * 1%;
  }
}