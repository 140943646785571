.filterUserPopover {

  .filterUserPopover_option {
    padding: 10px 30px 10px 10px;
  }

  .filterUserPopover_option,
  .filterUserPopover_header {
    border-bottom: 1px solid $gray-300;
  }

  &.dark-theme {

    .filterUserPopover_option,
    .filterUserPopover_header {
      border-bottom: 1px solid $gray-900;
    }

    .filterUserPopover_group {
      background: $material-theme-sys-dark-background;
      border: 1px solid $gray-900;
      color: white;
    }
  }
}

.filterUserPopover_group {
  max-width: 250px;
  width: 100%;
  border-radius: 4px;
  background: $gray-100;
  border: 1px solid $gray-300;
}

.filterUserPopover_option {

  &:hover {
    background-color: $gray-200;
  }

  &.dark-theme {
    &:hover {
      background-color: $material-theme-ref-neutral-neutral-24;
    }

  }
}