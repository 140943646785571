.payroll {

  p {
    margin: 0;
  }
}

.payroll_filter {
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;

  @include break_point_tablet {
    grid-auto-flow: row;
    gap: 1rem;
  }
}

.payroll_table {
  tbody {
    .hover:hover {
      background-color: $antiflash-white;
    }
  }
}

.dark-theme {
  .payroll_table {
    tbody {
      .hover:hover {
        background-color: $material-theme-ref-neutral-neutral-24;
      }
    }
  } 
}