.navbar {
  position: sticky;
  top: -1px;
  z-index: 3;
  padding: 0 !important;

  &.condense{
    height: 56px;
  }

  .containers {
    height: 100%;
  }

  .navbar-brand svg {
    width: 150px;

    @include break_point_laptop_sm {
      width: 120px;
    }
  }

  .nav-link {
    &.active {
      border-bottom: 0.1rem solid $emerald;

      @include break_point_laptop_sm {
        border: none;
      }
    }
  }

  .dropdown-menu[data-bs-popper] {
    left: auto;
    right: 0;
    min-width: 17rem;
  }
}

.navbarCustom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 0;
  height: 100%;

  @include break_point_laptop_sm {
    padding: 1em 0;
  }
}

#navbarSupportedContent {
  @include break_point_laptop_sm {
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 1;
    left: 0;
    padding: 1em 2em;
    border-bottom: 1px solid $alice-blue;
  }
}

.dark-theme {
  &.navbar {
    border-color: $material-theme-ref-neutral-neutral-24;

    .text-primary {
      color: $celadon !important;
    }
  }

  .navbarCustom {
    border-bottom: 1px solid $material-theme-ref-neutral-neutral-24;

    .nav-link {
      &.active {
        border-bottom: none;
      }
    }
  }

  #navbarSupportedContent {
    @include break_point_laptop_sm {
      border-bottom: 1px solid $material-theme-ref-neutral-neutral-24;
    }
  }
}

// --------------------------------------------------------------------
.nav-underline {
  .nav-link {
    color: $grey;
    background: transparent;
    border: none;
    width: 100%;
    text-align: left;

    &.active {
      color: $emerald;
      font-weight: 600;
      border-bottom: 0.1rem solid $emerald;
    }
  }
}

.nav-pills .nav-link {
  color: $grey;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background: $emerald;
  color: $white;
}

.navbar_user_info_mobile {
  display: grid;
  grid-auto-flow: column;
  gap: 1em;
  align-items: center;
  overflow-wrap: anywhere;
  justify-content: start;
  margin-bottom: 10px;

  .navbar_user_info_mobile_texts {
    h6 {
      color: $material-theme-sys-dark-surface;
    }

    p {
      color: $material-theme-sys-dark-background;
      font-size: 15px;
    }

    span {
      font-size: 15px;
      color: $material-theme-sys-dark-outline;

    }
  }
}

.dark-theme {
  .nav-underline {
    border-bottom: 0.1rem solid transparent;

    .nav-link {
      color: $material-theme-ref-neutral-neutral-95;

      &.active {
        color: $celadon;
        font-weight: 300;
        border-bottom: 0.1rem solid $celadon;
      }
    }
  }

  .nav-pills .nav-link {
    color: $material-theme-ref-neutral-neutral-95;
  }

  .nav-pills .nav-link.active,
  .nav-pills .show>.nav-link {
    background: $material-theme-ref-neutral-neutral-27;
    color: $material-theme-ref-neutral-neutral-95;
  }

  .navbar_user_info_mobile {

    .navbar_user_info_mobile_texts {
      h6 {
        color: white;
      }

      p {
        color: $material-theme-ref-neutral-neutral-95;
      }

      span {
        color: $material-theme-sys-dark-outline;
      }
    }
  }

  .navbar-toggler {
    color: white;
    border-color: white;
  }
}
