.filterTrackerByDate {
  display: flex;
  align-items: center;
  gap: 1em;

  @include break_point_mobile {
    display: grid;
    justify-content: space-between;
    justify-items: start;
    gap: 1.5em;
  }
}

.filterTrackerByDate_filterUser {
  margin-left: auto;
  display: flex;
  gap: 10px;

  @include break_point_mobile {
    grid-column: 2/3;
    grid-row: 1/2;
  }
}

.filterTrackerByDate_day {
  display: grid;
  grid-auto-flow: column;
  column-gap: 1.8rem;
  justify-items: start;

  button {
    padding: 0;
  }

  @include break_point_laptop_sm {
    grid-auto-flow: row;
  }
}

.filterTrackerByDate_hours_mobile {
  margin: 0;
  margin-left: auto;
}