.modalCustom {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgb(0 0 0 / 59%);
  z-index: 9999999999;
  display: grid;
  justify-content: center;
  align-items: center;
}

.modalCustom_content {
  background-color: $white;
}

.dark-theme {

  .modalCustom_content {
    background-color: $material-theme-sys-dark-surface-container;
  }
}