.submissionItem{
    p{
        color: #060A11;
        text-decoration: none;
    }
}

.dark-theme{
    .submissionItem{
        p{
            color: #fff;
        }
    }
}