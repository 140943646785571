.dropdownCustom {
    max-height: 300px;
    overflow: hidden auto;
}

.dropdown-sticky-search {
    position: sticky;
    top: -1px;
    padding-top: 5px;
    background: $white;

    .dropdown-item:hover,
    .dropdown-item:focus {
        background: $white;
    }
}

.dropdown-item {
    .searchInput {
        display: block;
        min-width: 200px;

        svg {
            left: 10px;
        }
    }

    &.active, &:active {
        color: $rich-black;
        background-color: $alice-blue-2;

        &.not-hover {
            background-color: $alice-blue-2;
        }
    }
}

.dropdownOrderItems {
    width: 250px;

    .dropdownOrderItems_item {
        display: flex;
        padding: 7px;

        .dropdownOrderItems_item_all {
            width: 100%;
        }

        p {
            cursor: pointer;
            margin: 0 !important;
            color: $material-theme-ref-neutral-neutral-24;
        }

        &:hover {
            background-color: lighten($emerald, 10%);
            color: $white;

            p {
                color: $white;
            }
        }

        &:active {
            background-color: inherit;
            color: $material-theme-ref-neutral-neutral-24;

            p {
                color: $material-theme-ref-neutral-neutral-24
            }
        }
    }
}

.dark-theme {
    .dropdown-sticky-search {
        background: $material-theme-ref-neutral-neutral-24;
    }

    .dropdown-menu {
        background: $material-theme-ref-neutral-neutral-24;
        color: $material-theme-ref-neutral-neutral-95;

        svg {
            fill: $material-theme-ref-neutral-neutral-95;
        }
    }

    .dropdown-item {
        color: $material-theme-ref-neutral-neutral-95;

        &:hover,
        &:active,
        &.active {
            background-color: $material-theme-ref-neutral-neutral-27;

            &.not-hover {
                background-color: $material-theme-ref-neutral-neutral-24;
            }
        }
    }

    .dropdownOrderItems {
        .dropdownOrderItems_item {
            p {
                color: $white;
            }

            &:hover {
                background-color: $material-theme-ref-neutral-neutral-27;
            }

            &:active {
                background-color: inherit;
                color: $white;
    
                p {
                    color: $white
                }
            }
        }
    }
}