.searchInput{
    position: relative;

    svg{
        position: absolute;
        z-index: 2;
        top: 10px;
        left: 12px;
    }
    input{
        padding-left:40px;
    }
}