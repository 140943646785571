.avatarModalSettings_avatar {
  background: $slate-gray;
  border-radius: 50%;
  height: 80px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.2em 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
}

.avatarModalSettings_form {
  display: flex;
  justify-content: center;
  justify-items: center;
  flex-direction: column;

  label {
    display: grid;
    gap: 1.2em;
  }
}

.avatarModalSettings_avatar_input {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
}

.avatarModalSettings_drag {
  position: relative;
  display: grid;
  justify-items: center;
}

.avatarModalSettings_upload {
  display: flex;
  align-items: center;
  gap: 7px;
  border: 1px solid $material-theme-ref-neutral-neutral-30;
  padding: 10px 22px;
  border-radius: 6px;
  color: white;
  cursor: pointer;
  justify-content: center;

  &:hover {
    background-color: $material-theme-sys-dark-background;
  }

  p {
    color: white;
  }
}