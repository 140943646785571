.settings {
  display: grid;
  grid-template-columns: 260px 1fr;
  gap: 3em;

  @include break_point_laptop_sm {
    grid-template-columns: 1fr;
  }

  p,
  h4 {
    margin: 0;
  }
}

.settings_navbar {
  @include break_point_laptop_sm {
    padding-bottom: 2em;
    border-bottom: 1px solid $material-theme-ref-neutral-neutral-30;
  }
}

.settings_avatar_section {
  display: grid;
  grid-auto-flow: column;
  gap: 12px;
  justify-content: start;

  @include break_point_laptop_sm {
    text-align: center;
    grid-auto-flow: row;
    justify-content: unset;
  }
}

.settings_avatar {
  @include break_point_laptop_sm {
    display: flex;
    justify-content: center;
  }
}

.settings_avatar_content {
  display: flex;
  align-self: start;
  position: relative;
  cursor: pointer;
}

.settings_edit_avatar {
  position: absolute;
  bottom: 0;
  right: -4px;
  background-color: white;
  border-radius: 50%;
  height: 23px;
  width: 23px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.settings_fullname {
  overflow-wrap: anywhere;
}

.creditCardWrapper{
  width: 54px;
}

.settings_submit {
  @include break_point_mobile {
    width: 100%;
  }
}
