.tooltips {
    display: none;
    place-content: center;
    color: white;
    text-shadow: 0 1px 0 #000;
    min-width: 300px;
    max-width: 340px;
    
    --border-angle: 0turn; // For animation.
    --main-bg: conic-gradient(
        from var(--border-angle),
        #212529,
        #212529 5%,
        #1D2020 60%,
        #212529 95%
      );
    
    border: solid 1px transparent;
    border-radius: 8px;
    --gradient-border: conic-gradient(from var(--border-angle), transparent 25%, #08f, #00DDD8 99%, transparent);
    
    background: 
      var(--main-bg) padding-box,
      var(--gradient-border) border-box, 
      var(--main-bg) border-box;
    
    background-position: center center;
  
    animation: bg-spin 3s linear infinite;
    @keyframes bg-spin {
      to {
        --border-angle: 1turn;
      }
    }

    &.show{
        display: grid;
    }
    h5{
      font-size: 1rem;
    }
    p{
        color: $antiflash-white;
        font-size: 1rem;
        &.small{
          margin-top: 5px;
          font-size: 0.9rem;
        }
    }
    .btn-x{
      padding: 2px 1px 0px 3px;
    }
}
  
@property --border-angle {
syntax: "<angle>";
inherits: true;
initial-value: 0turn;
}
  