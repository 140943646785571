.startTimeBtnTracker {

  @include break_point_mobile {
    position: fixed;
    bottom: 0em;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    z-index: 1;
    background: white;
    padding: 1.5em 20px;

    button {
      width: 100%;
    }
  }
}

.dark-theme {
  .startTimeBtnTracker {
    @include break_point_laptop_sm {
      background: $material-theme-sys-dark-surface;
    }
  }
}

.submitHoursTrackerBtn {
  display: flex;
  justify-content: flex-end;

  @include break_point_mobile {
    justify-content: unset;

    .submitHoursTrackerBtn_content {
      width: 100%;
    }

    button {
      width: 100%;
    }
  }
}

.submitHoursTrackerBtn_dropdown {
  width: 40px !important;
}