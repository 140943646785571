$backgrounds: (
  primary: (
    light: #ffffff,
    dark: $material-theme-sys-dark-surface
  ),
  secondary: (
    light: $alice-blue,
    dark: $material-theme-sys-dark-surface,
  )
);

@each $key, $value in $backgrounds {
  .background_#{$key} {
    background-color: map-get($value, light) !important;
  }
}

.dark-theme {
  @each $key, $value in $backgrounds {
    .background_#{$key} {
      background-color: map-get($value, dark) !important;
    }
  }
}