.dropdownDivider {
  border-top: 1px solid $material-theme-sys-dark-background;
  height: 0;
  margin: 0.7rem;
}

.dark-theme {
  .dropdownDivider {
    border-color: $material-theme-ref-neutral-neutral-30;
  }
}

.rounded-20 {
  border-radius: 20px;
}