.card{
    background: $white;
    border-color: $white;
    border-radius: 10px;
    .card-img-top{
        border-top-left-radius: 0.1rem;
        border-top-right-radius: 0.1rem;
    }
    .card-body{
        border-top: 0.1rem solid $alice-blue;
    }
    &.card-secondary{
        background: $material-theme-ref-neutral-neutral-95;
        &.active{
            border-color: $emerald;
        }
    }
}

.dark-theme{
    .card{
        background: $material-theme-sys-dark-surface-container;
        border-color: $material-theme-sys-dark-surface-container;
        .card-body{
            border-top: 0.1rem solid fade-out($slate-gray, 0.75);
            .card-text{
                color:$slate-gray;
            }
        }
        &.card-secondary{
            background: $material-theme-sys-dark-background;
            border-color: $material-theme-ref-neutral-neutral-24;
        }
    }
}