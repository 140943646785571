.containers {
  padding: 0 3em;
  max-width: 1400px;
  margin: 0 auto;

  @include break_point_tablet {
    padding: 0 20px;
  }
}

.wAll {
  width: 100%;
}

.content {
  scrollbar-color: $alice-blue $antiflash-white;
  min-height: calc(100vh - 123px) !important;
  background-color: $antiflash-white;

  &.content-condese {
    min-height: calc(100vh - 56px) !important;
  }

  &.dark-theme {
    scrollbar-color: $material-theme-sys-dark-surface $material-theme-sys-dark-surface-container;
  }

  @include break_point_laptop_sm {
    min-height: calc(100vh - 75px) !important;
  }
}

body {
  &::-webkit-scrollbar {
    width: 5px;
  }
}