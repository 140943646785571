.tracker {
  display: grid;

  @include break_point_mobile {
    padding-bottom: 5em;
  }
}

.tracker_time {
  display: grid;
  gap: 1em;
}
