.integration_azure {
  width: 50px;
  background: white;
  border-radius: 10px;
  height: 50px;
  object-fit: cover;
  padding: 4px;
}

.integration_card {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5em;

  @include break_point_tablet {
    display: grid;
    grid-template-columns: 50px 1fr;
  }

  @include break_point_mobile {
    grid-template-columns: 1fr;
    justify-items: center;
  }
}
.integration_card_title {

}

.integration_card_btns {
  display: flex;
  flex-direction: row;
  gap: 10px;

  @include break_point_tablet {
    grid-column: 1/3;
  }

  @include break_point_mobile {
    grid-column: unset;
  }
}