$color_icons: (
  logo: (
    light: $material-theme-sys-dark-surface,
    dark: #ffffff,
  )
);

@each $key, $value in $color_icons {
  .icon_#{$key} {
    fill: map-get($value, light);
  }
}

.dark-theme {
  @each $key, $value in $color_icons {
    .icon_#{$key} {
      fill: map-get($value, dark);
    }
  }
}