.landing_hero {
  display: grid;
  gap: 1.6em;
  justify-items: center;
  text-align: center;
  padding: 3em 0em 0;
  align-content: start;

  @include min_width_laptop_md {
    margin: 3em 0 0;
    gap: 3rem;
  }

  span {
    color: $grey
  }
}

.featured-on-section{
  margin-top:6rem;
  margin-bottom: 6rem;
}

.featured-on{
  font-size: 0.8rem;
}
.featured-brand{
  opacity:0.4;
}

.landing_title {
  font-size: 2rem;
  @include min_width_tablet {
    font-size: 3rem;
  }
}

.landing_short {
  max-width: 800px;
  width: 100%;
}

.landing_dashboard {
  img {
    width: 100%;
  }
}

.landing_feature {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2em;
  margin: 8em 0;

  @include break_point_laptop_sm {
    grid-template-columns: 1fr 1fr;
  }

  @include break_point_mobile {
    grid-template-columns: 1fr;

  }
}

.landing_feature_content {
  display: flex;
  flex-direction: column;
  gap: 10px;

  p {
    font-size: 16px;
    color: $white;
  }
  svg{
    height: 22px;
    width: 22px;
  }

  @include min_width_laptop_md {
    svg {
      height: 30px;
      width: 30px;
    }
  }
}

.landing_connect {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  align-items: center;

  h2 {
    color: $white
  }

  p {
    color: $white
  }
}

.landing_connect_icons {
  display: flex;
  gap: 4em;
  justify-content: center;

  @include break_point_mobile {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-self: center;
  }
}

.landing_img_text_bg {
  background: $material-theme-sys-dark-surface;
  background: linear-gradient(204deg, $material-theme-sys-dark-surface 35%, $material-theme-sys-dark-on-primary 50%, $material-theme-sys-dark-surface 65%);
}

.lading_img_text {
  display: flex;
  flex-direction: column;
  margin: 8em 0px;
  gap: 8em;
}

.landing_section {
  img {
    width: 100%;
  }

  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;

  @include break_point_laptop_sm {
    grid-template-columns: 1fr;
    gap: 3em;
  }
}

.landing_section_text {
  display: flex;
  flex-direction: column;
  padding: 2em;
  align-items: start;

  h2, h3, p {
    color: $white;
  }
  h3{
    font-size: 1rem;
  }

  @include break_point_laptop_sm {
    grid-row: 1/2;
    padding: 0;
  }
}

.lading_billing {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1em;

  h2 {
    color: $white
  }

  p {
    color: $white;
  }

  img {
    width: 100%;
  }
}

.landing_description {
  max-width: 560px;
  width: 100%;
}

.landing_end {
  display: flex;
  flex-direction: column;
  gap: 2em;
  margin: 8em 0;
  align-items: center;
  text-align: center;
}

.landing_end_btns {
  display: flex;
  gap: 2em
}

.landing_footer_bg {
  background: $material-theme-sys-dark-surface;
  background: linear-gradient(180deg, $material-theme-sys-dark-surface 40%, $material-theme-sys-dark-on-primary 100%);
}
.landing_footer {
  padding: 4em 0 2em;
  display: grid;
  gap: 3em;

  @include break_point_tablet {
    justify-items: center;
  }
}

.landing_logo {
  width: 200px;
}

.faq-wrapper{
  margin: 7rem auto;
}
