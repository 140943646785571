.connectModal_logo {
  width: 60px;
  background: white;
  border-radius: 50%;
  padding: 15px;
  height: 60px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.connectModal {
  form {
    grid-row-gap: 1em;
  }
}

.modalConnect_close {
  position: absolute;
  top: 1em;
  right: 1em;
}