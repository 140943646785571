.modalCenter {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.btnCloseModal {
	position: absolute;
	top: 1.7em;
	right: 1.7em;
}

.modal-close {
	margin: 0;

	svg {
		cursor: pointer;
		fill: $material-theme-sys-dark-surface-container-highest;
	}
}

.dark-theme {
	.modal {
		.modal-content {
			background: $material-theme-sys-dark-surface-container;
			color: $antiflash-white;
		}
	}

	.modal-header {
		border-bottom: 0.1rem solid $material-theme-ref-neutral-neutral-24;
	}

	.modal-footer {
		border-top: 0.1rem solid $material-theme-ref-neutral-neutral-24;
	}

	.btn-close {
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' fill='%23ebfdff' viewBox='0 0 256 256'%3E%3Cpath d='M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z'%3E%3C/path%3E%3C/svg%3E");
	}

	.modal-close svg {
		fill: white
	}
}

.suggestionPopupUpgradePlan {
	max-width: 1000px;
	width: 100%;

	img {
		max-width: 200px;
		width: 100%;

		@include break_point_mobile {
			max-width: 100px;
		}
	}
}