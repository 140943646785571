.bannerDatePicker {
  display: flex;
  gap: 1em;
  align-items: center;

  @include break_point_tablet {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.bannerDatePicker_return {
  button {
    padding: 0;
  }
}

.bannerDatePicker_dateRange {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: 1em;

  @include break_point_laptop_sm {
    grid-auto-flow: row;
    gap: 5px;
  }

  @include break_point_tablet {
    grid-column: 1/3;
    justify-content: start;
    grid-auto-flow: column;
    gap: 1em;
  }

  @include break_point_mobile {
    grid-auto-flow: row;
    gap: 5px;
  }
}

.bannerDatePicker_dateRange_up {
  grid-row: 1/2;
  grid-column: 1/2;

  @include break_point_tablet {
    grid-column: 1/3;
    grid-row: 2/3;
  }
}

.bannerDatePicker_period {
  margin-left: auto;

  @include break_point_tablet {
    grid-row: 1/2;
    grid-column: 2/3;
  }
}

.bannerDatePicker_period_up {
  @include break_point_mobile {
    grid-column: 1/2;
    margin-left: unset;
  }
}

.bannerDatePicker_period_custom {
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  gap: 16px;

  @include break_point_mobile {
    grid-auto-flow: row;
  }
}

.bannerDatePicker_period_custom_inputs {
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  gap: 16px;

  @include break_point_mobile {
    grid-auto-flow: row;
  }
}


.datePicker_custom {
  .rdrDayHovered {
    .rdrDayStartPreview {
      border-color: $emerald !important;
    }
  }

  .rdrDayToday {
    span span::after {
      background: $emerald;
    }
  }
}

.dark-theme {
  .datePicker_custom {
    background: $material-theme-ref-neutral-neutral-24 !important;

    .rdrDateDisplayWrapper {
      background-color: $material-theme-ref-neutral-neutral-24 !important;
      border-bottom: 1px solid $material-theme-ref-neutral-neutral-27;
    }

    select {
      color: $white !important;

      &:hover {
        background-color: $material-theme-ref-neutral-neutral-27 !important;
      }
    }

    .rdrNextButton,
    .rdrPprevButton {
      background-color: $material-theme-ref-neutral-neutral-27 !important;

    }

    .rdrNextButton {
      i {
        border-color: transparent transparent transparent $white;
      }
    }

    .rdrPprevButton {
      i {
        border-color: transparent $white transparent transparent;
      }
    }

    .rdrDayNumber {
      span {
        color: $white !important;
      }
    }

    .rdrDayPassive {
      .rdrDayNumber {
        span {
          color: $material-theme-sys-dark-outline !important;
        }
      }
    }

    .rdrDayHovered {
      .rdrDayStartPreview {
        border-color: $celadon !important;
      }
    }

    .rdrDayToday {
      span span::after {
        background: $celadon;
      }
    }

    .rdrDateInput {
      background: $material-theme-ref-neutral-neutral-27;
      border: none;

      input {
        color: white;
      }
    }
  }
}