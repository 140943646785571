.cookieBanner{
    border: 1px solid #363A39;
    background: rgba(16, 20, 20, 0.8);
    backdrop-filter: blur(5px);
    z-index: 10;
    position: fixed;
    bottom: 5px;
    left: 0;
    padding: 20px;
    @include min_width_tablet {
        left: 12.5%;
        padding: 20px;
    }
}