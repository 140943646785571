.CustomTooltipBarChart, .customTooltipBarChartPerDay {
  padding: 1.5em;
  border-radius: 10px;
  border: 1px solid $material-theme-sys-dark-outline;
  min-width: 180px;

  p {
    margin: 0;
    color: black;
  }
}

.CustomTooltipBarChart {
  background-color: $material-theme-sys-dark-secondary;

  p {
    color: black !important;
  }
}

.customTooltipBarChartPerDay {
  background-color: #ffffff;
}

.dark-theme {
  .customTooltipBarChartPerDay {
    background-color: $material-theme-sys-dark-surface-container;
  } 

  .CustomTooltipBarChart {
    background-color: $material-theme-sys-dark-secondary;
  }
}
