@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@300;400;500;700&display=swap');

body {
    font-family: 'DM Sans', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $grey;
    font-weight: 400;
}

h1 {
    font-size: 2.25rem;
}

p {
    color: $grey;
    font-weight: 300;
    a {
        color: $material-theme-sys-dark-primary;
    }
}

a {
    color: $material-theme-sys-dark-primary;
}

hr {
    border-top: 0.1rem solid fade-out($slate-gray, 0.75);
    opacity: 1;
}

.dark-theme {
    body {
        background: $rich-black;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $material-theme-ref-neutral-neutral-95;
    }

    p {
        color: $material-theme-sys-dark-outline;
    }

    hr{
        border-top: 1px solid $material-theme-ref-neutral-neutral-24;
    }
}

.pointer {
    cursor: pointer;
}

.cursor-disabled {
    cursor: no-drop;
}