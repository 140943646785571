
.landing_footer_options {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 2em;
    justify-content: space-between;
  
    @include break_point_tablet {
      grid-template-columns: 1fr;
  
      text-align: center;
    }
  }
  
  .landing_footer_option {
    display: flex;
    flex-direction: column;
  
    p {
      display: grid;
      color: $white;
    }
  
    span {
      font-size: 16px;
      color: $white;
    }
  }
  
  .landing_footer_option_spans {
    display: flex;
    flex-direction: column;
  }
  
  .landing_footer_links {
    gap: 14px;
  
    a {
      color: white;
      text-decoration: none;
    }
  }
  
  .landing_social {
    display: flex;
    flex-direction: row;
    gap: 1.5em;
  
    img {
      width: 30px;
    }
  }
  
  .landing_divider {
    height: 1px;
    width: 100%;
    background-color: $material-theme-sys-dark-secondary;
  }
  
  .landing_copy {
    display: flex;
    justify-content: space-between;
  
    @include break_point_tablet {
      flex-direction: column;
      align-items: center;
    }
  }
  
  .landing_copy_links {
    display: flex;
    gap: 10px;
  
    a {
      color: $material-theme-sys-dark-outline;
      text-decoration: none;
    }
  }