.custom-tooltip {
  --bs-tooltip-max-width: 500px;
  --bs-tooltip-opacity: 1;
  --bs-tooltip-arrow-width: 0;
  --bs-tooltip-arrow-height: 0;
  border-radius: 10px;
  left: 0 !important;


  @include break_point_mobile {
    --bs-tooltip-max-width: 300px;
  }
}

.custom-tooltip-ligth {
  --bs-tooltip-bg: $alice-blue;
  background: $alice-blue;
}

.custom-tooltip-dark {
  --bs-tooltip-bg: $material-theme-sys-dark-surface;
  background: $material-theme-sys-dark-surface;
  color: $white;
}