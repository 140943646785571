.checkout{
    .logo{
        fill: #fff;
        width: 135px;
    }
    .checkout_header {
        padding: 2em 0;
    }
    .form-check-label{
        color: $material-theme-sys-dark-outline;
    }
    .seats-input{
        max-width:75px;
    }
    .summary-card{
        background: $material-theme-ref-neutral-neutral-27;
    }
}

@media(min-width: 768px){
    .checkout{
        .summary{
            position: -webkit-sticky;
            position: sticky;
            top: 30px;
        }
    }
}