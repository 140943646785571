.timesheet-card {
  min-height: 45vh;
}

.timeSheetCondensed {
  display: grid;
  grid-template-columns: 1fr;

  &.timeSheetCondensed_detial {
    grid-template-columns: 1fr 500px;
  }
}