.collapseItemButton{
    border-bottom: 1px solid #8893924F;
    .btn{
        color: #fff;
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

.collapseItemContent{
    .card{
        background-color: transparent;
        border: none;
    }
}