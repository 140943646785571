.badge{
    font-weight: 300;
    border-radius: 0.55rem;
    padding: 6px 12px;
}

.text-bg-primary{
    background: $emerald !important;
    color: $white !important;
}
.text-bg-secondary{
    background: $alice-blue !important;
    color: $slate-gray !important;
}
.text-bg-terciary{
    background: $rich-black !important;
    color: $antiflash-white !important;
}
.text-bg-quaternaly{
    background: $material-theme-ref-neutral-neutral-95 !important;
    color: black !important;
}
.text-bg-danger{
    background: $fuchsia-rose !important;
    color: $antiflash-white !important;
}

.text-bg-info{
    background: $ocean-blue !important;
    color: $antiflash-white !important;
}

.text-bg-success{
    background: $celadon2 !important;
    color: $rich-black !important;
}

.dark-theme{
    .text-bg-primary {
        background: $celadon !important;
        color: $rich-black !important;
    }

    .text-bg-secondary{
        background: $material-theme-sys-dark-surface-container !important;
        border: 1px solid $material-theme-ref-neutral-neutral-27;
        color: $antiflash-white !important;
    }
    .text-bg-terciary{
        background: $slate-gray !important;
        color: $antiflash-white !important;
    }
    .text-bg-quaternaly{
        background: $antiflash-white !important;
        color: black !important;
    }
}