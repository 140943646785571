.login{
    display: grid;
    min-height: 100vh;
    .or{
        background-color: #fff;
        padding: 0 15px;
    }
    .logo-wrapper{
        svg{
            fill: #000;
        }
    }
    .eye-button{
        right: -4px;
        top: 26px;
    }
}

.login-required-password  {
    background-color: $grey;
    border-radius: 5px;
}

.dark-theme{
    .login{
        .or{
            background-color: $material-theme-sys-dark-surface;
            color: $slate-gray;
        }
        .logo-wrapper{
            svg{
                fill: #fff;
            }
        }
    }
}