.users {
    .users-card {
        min-height: 52vh;
    }

    .btn-add {
        display: none;
    }
}

.tableMobileUserContent {
    display: flex;
    flex-direction: column;
    gap: 3px;
    align-items: flex-start;
    padding: 15px 5px !important;
    overflow-wrap: anywhere;
}