.ticket{
    color: $slate-gray;
    text-decoration: none;
    font-weight: 600;
}
.dark-theme{
    .ticket{
        color: $antiflash-white;
        text-decoration: none;
        font-weight: 600;
    }
}