/* Light Color Palette */
$rich-black: #060A11;
$celadon: #31f7f2;
$celadon2:#A2EEB3;
$emerald: #288373;
$indigo:#262162;
$slate-gray: #6d7f9b;
$alice-blue: #E2E8F0;
$alice-blue-2: #F3F4F3;
$antiflash-white: #EDEDEDED;
$fuchsia-rose-ligth: #BA1A1A;
$ocean-blue: #0d6efd;
$warning: #e9c46a ;
$white: #ffffff;
$grey: #5B5F5E;
$error: #FFDAD6;
$bg-avatar: #B1C8E8;

/* Dark Color Palette */
$gunmetal: #27303D;
$rich-black-2: #121922;

$material-theme-sys-dark-surface: #101414;
$material-theme-sys-dark-primary: #00DDD8;
$material-theme-sys-dark-on-primary: #003736;
$material-theme-sys-dark-surface-container: #1D2020;
$material-theme-sys-dark-secondary: #B0CCCA;
$material-theme-sys-dark-background: #191C1C;
$material-theme-sys-dark-outline: #889392;
$material-theme-ref-neutral-neutral-24: #363A39;
$material-theme-ref-neutral-neutral-27: #272B2A;
$material-theme-ref-neutral-neutral-30: #444747;
$material-theme-ref-neutral-neutral-95: #EFF1F0;
$material-theme-sys-dark-surface-container-highest:#323535;
$material-theme-sys-dark-on-error:#690005;
$material-theme-sys-dark-error: #FFB4AB;
$fuchsia-rose: #BC5776ff;
