.popoverCustom {
  position: relative;
  display: flex;
  justify-content: flex-start;
}

.popoverCustom_body {
  position: absolute;
  z-index: 999;
}

.popoverCustom_body_right {
  top: 0;
  left: 100%;

  
  @include break_point_mobile {
    left: 0%;
    top: 100%;
  }
}

.popoverCustom_body_top-right {
  bottom: 113%;
  right: 0;
}

.popoverCustom_close {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: transparent;
  z-index: 998;
  border: none;
  cursor: auto;

  &:active,
  &:focus {
    outline: none;
  }
}

.popoverCustom_hover {
  .popoverCustom_body {
    display: none;
  }

  &:hover {
    .popoverCustom_body {
      display: grid;
    }
  }
}