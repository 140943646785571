.form-check-input {
    background-color: $slate-gray;
    border: 0.1rem solid fade-out($slate-gray, 0.75);
}

.form-check-input:checked {
    background-color: $emerald;
    border-color: $emerald;
}

.dark-theme {
    .form-check-input:checked {
        background-color: $celadon;
        border-color: $celadon;
    }
}

.form-check-input[role=switch] {
    &.switch_both {
        background-color: $emerald;
        border: 0.1rem solid $emerald;
    
        &:checked {
            background-color: $celadon;
            border-color: $celadon;
        }   
    }
}