.content{
    min-height: 100vh;
    padding: 0 0 2em 0!important;
}


.content{
    &.dark-theme{
        background: $material-theme-sys-dark-surface;
    }
}

.content_out {
    display: grid;
    min-height: 100vh !important;
}

.content-pb-0 {
    padding-bottom: 0 !important;
}