.alert{
    &.alert-info{
        p{
            color: #087990;
        }
    }
}

.alert-warning {
    background-color: #FCE598;
    color: #694e00;
    padding: 0.8em;
    border-radius: 4px;
}

.dark-theme{
    .alert{
        background-color: $material-theme-sys-dark-surface-container;
        border-color: $rich-black-2;
        h6{
            color: $antiflash-white;
        }
        &.alert-danger{
            background-color: rgb(44, 11, 14);
            border-color: rgb(132, 32, 41);
            p{
                color: $material-theme-sys-dark-error;
            }
            svg{
                fill: $material-theme-sys-dark-on-error;
            }
        }
    }
    .alert-info{
        p{
            color: #889392;
        }
        svg{
            fill: $ocean-blue;
        }
    }
    .alert-warning{
        svg{
            fill: $warning;
        }
    }
    .alert-success{
        svg{
            fill: $celadon2;
        }
    }
    
}