.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 9999999999;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #00000061;
}

.spinner {
  width: 56px;
  height: 56px;
  display: grid;
  animation: spinner-plncf9 4s infinite;
}

.spinner::before,
.spinner::after {
  content: "";
  grid-area: 1/1;
  border: 9px solid;
  border-radius: 50%;
  border-color: $emerald $emerald #0000 #0000;
  mix-blend-mode: darken;
  animation: spinner-plncf9 1s infinite linear;
}

.spinner::after {
  border-color: #0000 #0000 #dbdcef #dbdcef;
  animation-direction: reverse;
}

@keyframes spinner-plncf9 {
  100% {
    transform: rotate(1turn);
  }
}

.dark-theme {
  .spinner::before {
    border-color: $material-theme-ref-neutral-neutral-24 $material-theme-ref-neutral-neutral-24 #0000 #0000;
  }
}