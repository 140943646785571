.form-label {
    color: $slate-gray;
    font-size: 0.85rem;
    margin-bottom: 0.25rem;
}

.form-check-label {
    .form-select {
        position: relative;
        top: -8px;
    }
}

.input-primary {
    background-color: $emerald !important;
    color: $white !important;

    &::placeholder {
        color: $white
    }
}

.form-control,
.form-select {
    @include border-radius(0.3rem);
    border: 0.1rem solid fade-out($slate-gray, 0.75);
    font-weight: 400;
    background: $alice-blue-2;

    &:focus {
        border-color: $emerald;
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgba(162, 238, 179, 0.25);
    }

    &:disabled {
        background: $antiflash-white;
    }

    &.is-invalid {
        color: $material-theme-sys-dark-error !important;
    }
}

.invalid-feedback {
    color: $material-theme-sys-dark-error !important;
}

.dark-theme {
    .input-primary {
        background-color: $material-theme-sys-dark-background !important;
        color: $antiflash-white !important;
    
        &::placeholder {
            color: $material-theme-ref-neutral-neutral-24 !important
        }
    }

    .form-label {
        color: $material-theme-ref-neutral-neutral-95;
    }

    .form-control-plaintext{
        color: $material-theme-sys-dark-outline;
    }

    .form-control,
    .form-select {
        background: $material-theme-sys-dark-background;
        color: $antiflash-white;
        border: none;

        svg {
            fill: $material-theme-ref-neutral-neutral-24
        }

        &:focus {
            border-color: $celadon;
            outline: 0;
            box-shadow: 0 0 0 0.25rem rgba(162, 238, 179, 0.25);
        }

        &::-webkit-input-placeholder {
            /* Edge */
            color: $material-theme-ref-neutral-neutral-24;
        }

        &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: $material-theme-ref-neutral-neutral-24;
        }

        &::placeholder {
            color: $material-theme-ref-neutral-neutral-24;
        }

        &:disabled {
            background: $material-theme-sys-dark-background;
            color: $material-theme-sys-dark-outline;
        }

        &.form-control-outline {
            background: transparent;
            border: 1px solid $material-theme-ref-neutral-neutral-24;

            &:hover {
                border: 1px solid $material-theme-ref-neutral-neutral-24;
            }

            &:focus {
                background: $material-theme-sys-dark-background;
                border: 1px solid $material-theme-sys-dark-primary;
            }

            &:disabled {
                border: 1px solid $material-theme-ref-neutral-neutral-30;
                color: $material-theme-ref-neutral-neutral-30;
            }
        }

        &.is-invalid {
            border: 1px solid $material-theme-sys-dark-on-error;
            color: $material-theme-sys-dark-error;
        }
    }

    .form-select {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: right 0.75rem center;
        background-size: 16px 12px;
    }

    .form-text {
        color: $material-theme-sys-dark-secondary;
    }

    .invalid-feedback {
        color: $material-theme-sys-dark-error;
    }
}