.messagesTrackerStatusHours {
  display: grid;
  grid-auto-flow: column;
  gap: 1em;
  max-width: 800px;
  margin: 0 auto;
  justify-content: start;
}

.messagesTrackerStatusHours_btn {
  margin-left: auto;

  @include break_point_mobile {
    grid-row: 2/3;
    grid-column: 1/3;
    margin: 0 auto;
  }
}