.dashboard_card {
  padding: 5em;

  @include break_point_tablet {
    padding: 2em;
  }
}

.dashboard_card_small {
  padding: 2em;

  @include break_point_laptop_md {
    padding: 5em;
  }

  @include break_point_tablet {
    padding: 2em;
  }
}

.profile_graph {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;

  @include break_point_laptop_md {
    grid-template-columns: 1fr;
  }
}