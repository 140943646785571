.btn{
    border-radius: 0.1rem;
    font-weight: 500;
    @extend .rounded;
    svg{
        top: -2px;
        position: relative;
        margin-right: 2px;
    }
}

.btn-lg{
    font-size: 1rem;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn.dropdown-toggle-split:first-child, .btn-group > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.btn-group > .btn:nth-child(n+3), .btn-group > :not(.btn-check) + .btn, .btn-group > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.btn-primary{
    background: $emerald !important;
    border-color: $emerald !important;
    color: $white !important;

    svg {
        fill: $white
    }

    &:hover, &.hover{
        background: lighten($emerald, 10%);
        border-color: lighten($emerald, 10%);
    }
    &:focus, &:focus-visible, &.active, &:active, &:first-child:active, &:not(.btn-check) + .btn:active{
        outline: none !important;
        background: darken($emerald, 10%) !important;
        border-color: darken($emerald, 10%) !important;
    }
    &:disabled, &.disabled{
        color: lighten($white, 10%);
        background: lighten($emerald, 10%);
        border-color: lighten($emerald, 10%);
    }
}

.btn-outline-primary {
    border-color: $material-theme-sys-dark-primary;
    color: $material-theme-sys-dark-primary;
    
    svg{
        fill: white;
    }

    &:hover, &.hover{
        color: lighten($material-theme-sys-dark-primary, 10%);
        border-color: lighten($material-theme-sys-dark-primary, 10%);
        background-color: inherit;
    }
    &:focus, &:focus-visible, &.active, &:active, &:first-child:active, &:not(.btn-check) + .btn:active{
        outline: none !important;
        border-color: darken($material-theme-sys-dark-primary, 10%) !important;
        color: lighten($material-theme-sys-dark-primary, 10%) !important;
    }
    &:disabled, &.disabled{
        color: lighten($material-theme-sys-dark-primary, 10%);
        border-color: lighten($celadon, 10%);
    }
}

.btn-secondary{
    background: $alice-blue-2;
    border-color: $alice-blue;
    color: $grey;
    
    svg{
        fill: $slate-gray;
    }
    &:hover, &.hover{
        color: $grey;
        background: lighten($alice-blue-2, 1%);
        border-color: lighten($alice-blue, 1%);
    }
    &:focus, &:focus-visible, &.active, &:active, &:first-child:active{
        outline: none !important;
        background: darken($alice-blue-2, 3%);
        border-color: darken($alice-blue, 3%);
        color: $grey;
    }
    &:disabled, &.disabled{
        color: lighten($slate-gray, 10%);
        background: lighten($alice-blue-2, 1%);
        border-color: lighten($alice-blue, 1%);
    }

    &.active2 {
        background: #565e64 !important;
        border-color: #565e64 !important;
        color: $white !important;
    }
}

.btn-terciary{
    background: $rich-black;
    border-color: $rich-black;
    color: $antiflash-white;
    &:hover, &.hover{
        color: $antiflash-white;
        background: lighten($rich-black, 10%);
        border-color: lighten($rich-black, 10%);
    }
    &:focus, &:focus-visible, &.active, &:active, &:first-child:active{
        outline: none !important;
        background: darken($rich-black, 3%);
        border-color: darken($rich-black, 3%);
        color: $antiflash-white;
    }
    &:disabled, &.disabled{
        color: lighten($antiflash-white, 10%);
        background: lighten($rich-black, 1%);
        border-color: lighten($rich-black, 1%);
    }
}

.btn-ghost{
    background: transparent;
    border-color: transparent;
    color: $rich-black;
    &:hover, &.hover{
        color: $rich-black;
        background: transparent;
        border-color: transparent;
    }
    &:focus, &:focus-visible, &.active, &:active, &:first-child:active{
        outline: none !important;
        background: transparent;
        border-color: transparent;
        color: $rich-black;
    }
    &:disabled, &.disabled{
        color: $rich-black;
        background: transparent;
        border-color: transparent;
    }
}

.btn-danger{
    background: $error;
    border-color: $error;
    color: $fuchsia-rose-ligth;

    &:hover{
        color: $fuchsia-rose-ligth;
        background: lighten($error, 2%);
        border-color: lighten($error, 2%);
    }
    &:focus, &:focus-visible, &.active, &:active, &:first-child:active{
        outline: none !important;
        background: darken($error, 2%);
        border-color: darken($error, 2%);
        color: $fuchsia-rose-ligth;
    }
    &:disabled, &.disabled{
        color: lighten($fuchsia-rose-ligth, 2%);
        background: lighten($error, 2%);
        border-color: lighten($error, 2%);
    }
}

.btn-google{
    font-family: 'Roboto', sans-serif;
    padding: 8px 15px;
    background: #E2E8F0;
    color: #6d7f9b;
    border-radius: 5px;
    box-shadow: none;
    height: 42px;
    border: 1px solid #E0E7FF;
    .google-button__icon {
        display: inline-block;
        vertical-align: middle;
        margin: 0 0 8px 8px;
        width: 18px;
        height: 18px;
        box-sizing: border-box;
    }
    .google-button__text {
        display: inline-block;
        vertical-align: middle;
        padding: 0 24px;
        font-size: 13px;
        font-weight: bold;
        font-family: 'Roboto',arial,sans-serif;
    }
}

.btn-quaternary {
    background: $emerald;
    border-color: $emerald;
    color: $white !important;

    svg {
        fill: $white
    }

    &:hover, &.hover{
        background: lighten($emerald, 10%);
        border-color: lighten($emerald, 10%);
    }

    &:focus, &:focus-visible, &.active, &:active, &:first-child:active, &:not(.btn-check) + .btn:active{
        outline: none !important;
        background: darken($emerald, 10%);
        border-color: darken($emerald, 10%);
    }

    &:disabled, &.disabled{
        background: lighten($emerald, 10%);
        border-color: lighten($emerald, 10%);
    }
}

.dark-theme{
    .btn-primary{
        background: $material-theme-sys-dark-primary !important;
        border-color: $material-theme-sys-dark-primary !important;
        color: $material-theme-sys-dark-on-primary !important;

        svg {
            fill: $material-theme-sys-dark-on-primary
        }

        &:hover, &.hover{
            color: $rich-black;
            background: lighten($material-theme-sys-dark-primary, 10%);
            border-color: lighten($material-theme-sys-dark-primary, 10%);
        }
        &:focus, &:focus-visible, &.active, &:active, &:first-child:active, &:not(.btn-check) + .btn:active{
            outline: none !important;
            background: darken($material-theme-sys-dark-primary, 10%) !important;
            border-color: darken($material-theme-sys-dark-primary, 10%) !important;
            color: $rich-black !important;
        }
        &:disabled, &.disabled{
            color: lighten($rich-black, 10%);
            background: lighten($celadon, 10%);
            border-color: lighten($celadon, 10%);
        }
    }

    .btn-secondary{
        background: $material-theme-sys-dark-surface-container;
        border-color: $material-theme-ref-neutral-neutral-24;
        color: $antiflash-white;
        svg{
            fill: $antiflash-white;
        }
        &:hover, &.hover{
            color: $antiflash-white;
            background: lighten($material-theme-sys-dark-surface-container, 1%);
            border-color: lighten($material-theme-ref-neutral-neutral-24, 1%);
        }
        &:focus, &:focus-visible, &.active, &:active, &:first-child:active{
            outline: none !important;
            background: lighten($material-theme-sys-dark-surface-container, 7%);
            border-color: lighten($material-theme-ref-neutral-neutral-24, 7%);
            color: $antiflash-white;
        }
        &:disabled, &.disabled{
            color: $antiflash-white;
            background: lighten($material-theme-sys-dark-surface-container, 1%);
            border-color: lighten($material-theme-ref-neutral-neutral-24, 1%);
        }
    }

    .btn-terciary{
        background: $slate-gray;
        border-color: $slate-gray;
        color: $antiflash-white;
        &:hover, &.hover{
            color: $antiflash-white;
            background: lighten($slate-gray, 10%);
            border-color: lighten($slate-gray, 10%);
        }
        &:focus, &:focus-visible, &.active, &:active, &:first-child:active{
            outline: none !important;
            background: darken($slate-gray, 3%);
            border-color: darken($slate-gray, 3%);
            color: $antiflash-white;
        }
        &:disabled, &.disabled{
            color: lighten($antiflash-white, 10%);
            background: lighten($slate-gray, 1%);
            border-color: lighten($slate-gray, 1%);
        }
    }

    .btn-ghost{
        background: transparent;
        border-color: transparent;
        color: $antiflash-white;
        &:hover, &.hover{
            color: $antiflash-white;
            background: transparent;
            border-color: transparent;
        }
        &:focus, &:focus-visible, &.active, &:active, &:first-child:active{
            outline: none !important;
            background: transparent;
            border-color: transparent;
            color: $antiflash-white;
        }
        &:disabled, &.disabled{
            color: $antiflash-white;
            background: transparent;
            border-color: transparent;
        }
    }

    .btn-ghost-primary{
        background: transparent;
        border-color: transparent;
        color: $material-theme-sys-dark-primary;
        &:hover, &.hover{
            color: $antiflash-white;
            background: transparent;
            border-color: transparent;
        }
        &:focus, &:focus-visible, &.active, &:active, &:first-child:active{
            outline: none !important;
            background: transparent;
            border-color: transparent;
            color: $antiflash-white;
        }
        &:disabled, &.disabled{
            color: $antiflash-white;
            background: transparent;
            border-color: transparent;
        }
    }

    .btn-google{
        background: $material-theme-sys-dark-background;
        border-color: $material-theme-ref-neutral-neutral-24;
        color: $material-theme-ref-neutral-neutral-95;
    }

    .btn-quaternary{
        background: $material-theme-sys-dark-surface-container;
        border-color: $material-theme-ref-neutral-neutral-24;
        color: $antiflash-white;
        svg{
            fill: $antiflash-white;
        }
        &:hover, &.hover{
            color: $antiflash-white;
            background: lighten($material-theme-sys-dark-surface-container, 1%);
            border-color: lighten($material-theme-ref-neutral-neutral-24, 1%);
        }
        &:focus, &:focus-visible, &.active, &:active, &:first-child:active{
            outline: none !important;
            background: darken($material-theme-sys-dark-surface-container, 3%);
            border-color: darken($material-theme-ref-neutral-neutral-24, 3%);
            color: $antiflash-white;
        }
        &:disabled, &.disabled{
            color: $antiflash-white;
            background: lighten($material-theme-sys-dark-surface-container, 1%);
            border-color: lighten($material-theme-ref-neutral-neutral-24, 1%);
        }
    }

    .btn-danger{
        background: $fuchsia-rose;
        border-color: $fuchsia-rose;
        color: $white;
    
        &:hover{
            color: $white;
            background: lighten($fuchsia-rose, 10%);
            border-color: lighten($fuchsia-rose, 10%);
        }
        &:focus, &:focus-visible, &.active, &:active, &:first-child:active{
            outline: none !important;
            background: darken($fuchsia-rose, 10%);
            border-color: darken($fuchsia-rose, 10%);
            color: $white;
        }
        &:disabled, &.disabled{
            color: lighten($white, 10%);
            background: lighten($fuchsia-rose, 10%);
            border-color: lighten($fuchsia-rose, 10%);
        }
    }
}