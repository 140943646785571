.timeTrackerItem{
  border-bottom: 0.1rem solid fade-out($slate-gray, 0.75);
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  gap: 1rem;

  @include break_point_mobile {
    justify-content: unset;
  }
}

.timeTrackerItem_actions {
  display: grid;
  grid-auto-flow: column;
  align-content: center;
  gap: 10px;

  button {
    min-width: 90px;
  }

  @include break_point_mobile {
    grid-row: 2/3;
    justify-content: space-between;
    margin-left: auto;
  }
}