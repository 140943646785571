.popoverNotesTimeEntry {
  overflow-wrap: anywhere;
  max-width: 400px;
  border: 1px solid $alice-blue
}

.popoverNotesTimeEntry_date {
  font-size: 13px;
}

.dark-theme {
  .popoverNotesTimeEntry {
    border-color: $material-theme-sys-dark-outline;
  }
}
