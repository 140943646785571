.pagination{
    .page-item{
        &.disabled{
            .page-link{
                color: $slate-gray;
            }
        }     
        &.active{
            .page-link{
                color: $antiflash-white;
                background: $emerald;
                border-color: $emerald;
            }
        }   
        .page-link{
            background: $antiflash-white;
            color: $slate-gray;
            font-weight: 500;
        }
    } 
}

.dark-theme{
    .pagination{
        .page-item{
            &.disabled{
                .page-link{
                    color: #6D7F9B;
                }
            }     
            &.active{
                .page-link{
                    color: $antiflash-white;
                    background: $material-theme-ref-neutral-neutral-24;
                    border-color: $material-theme-ref-neutral-neutral-24;
                }
            }   
            .page-link{
                background: $material-theme-sys-dark-surface-container;
                color: $antiflash-white;
                border-color: $material-theme-ref-neutral-neutral-24;
            }
        } 
    }
}