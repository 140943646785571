.pricing{
    h1{
        font-size: 2.5rem;
    }
    
    .card{
        margin-bottom: 2rem;
        h3{
            font-size: 1rem;
            text-align: center;
        }
        h2{
            font-size: 1.5rem;
            text-align:center;
        }
    }

    .faq{
        margin-top: 6rem;
        margin-bottom: 6rem;
        h2{
            margin-bottom: 2rem;
        }
        .btn{
            color: #889392;
            font-size: 1rem;
            border-radius: 0 !important;
            border-bottom: 0.1rem solid #363A39;
            padding-bottom: 25px;
        }
        .card.card-body{
            background: transparent;
            padding: 0;
            border: none;
            min-height:0;
            padding-bottom: 2rem;
        }
    }
}

@media(min-width: 768px){
    .pricing{
        h1{
            font-size: 3rem;
        }
        .card{
            min-height: 711px;
        }
        .faq{
            .btn{
                font-size: 1.2rem;
            }
        }
    }
}