.cardDetailsTimeEntry_avatar {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    height: 100%;
    width: 2px;
    background-color: $emerald;
    z-index: 0;
    border-radius: 4px;
  }

  .avatarImage {
    z-index: 1;
    position: relative;
  }
}

.dark-theme {
  .cardDetailsTimeEntry_avatar {
    &::before {
      background-color: $bg-avatar;
    }
  }
}