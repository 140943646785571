.daysOfWeek {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  margin-bottom: 1px;

  @include break_point_tablet {
    grid-auto-flow: unset;
    grid-template-columns: repeat(7, 1fr);
    gap: 3px;
  }
}

.daysOfWeek_day {
  background-color: transparent;
  border: none;
  padding: 0;
  text-align: start;
  padding: 10px;

  &.active {
    color: $emerald !important;
    border-bottom: 1px solid $emerald;
  }

  @include break_point_mobile {
    font-size: 13px;
    padding: 10px 0px;
  }
}

.daysOfWeek_total {
  display: flex;
  flex-direction: column;
  text-align: end;

  @include break_point_tablet {
    grid-row: 1/2;
    grid-column: 1/8;
    justify-self: end;
  }

  @include break_point_mobile {
    flex-direction: row;
    justify-content: space-between;
    font-size: 18px;
  }
}

.dark-theme {
  .daysOfWeek_day {
    &.active {
      color: $celadon !important;
      border-bottom: 1px solid $celadon;
    }
  }

}