.modalFilterUserMobile {
  display: flex;
  flex-direction: column;
  gap: 15px;

  p {
    margin: 0;
  }
}
.modalFilterUserMobile_optionRole {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
.modalFilterUserMobile_options {
  &.role_active {
    border-color: $emerald !important;
    color: $emerald !important
  }
}

.dark-theme {
  .modalFilterUserMobile_options {
    &.role_active {
      border-color: $celadon !important;
      color: $celadon !important;
    }
  }
}