.activity-log-user{
    position:relative;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 2px;
        height: 100%;
        background-color: #6d7f9b; 
        z-index: 0;
    }
    .activity-log-user-image{
        position: relative;
        z-index: 1;
    }
}