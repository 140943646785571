// Break points
@mixin break_point_laptop_md {
  @media (max-width: 1281px) {
    @content;
  }
}

@mixin break_point_laptop_sm {
  @media (max-width: 991px) {
    @content;
  }
}

@mixin break_point_tablet {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin break_point_mobile {
  @media (max-width: 575px) {
    @content;
  }
}


// Min
@mixin min_width_laptop_md {
  @media (min-width: 1281px) {
    @content;
  }
}

@mixin min_width_laptop_sm {
  @media (min-width: 991px) {
    @content;
  }
}

@mixin min_width_tablet {
  @media (min-width: 767px) {
    @content;
  }
}

@mixin min_width_mobile {
  @media (min-width: 575px) {
    @content;
  }
}