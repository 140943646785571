.project {
	h1 {
		.badge {
			font-size: 1rem;
			position: relative;
			top: -5px;
		}
	}

	.project-container {
		border-left: 0.1rem solid fade-out($slate-gray, 0.75);

		@include break_point_laptop_sm {
			border-left: none;
		}
	}
}

.tasksbudget {
	h3 {
		font-size: 2rem;

		span {
			position: relative;
			top: -0.25rem;
			font-size: 1rem;
			color: $material-theme-sys-dark-outline;
		}
	}
}

.dark-theme {
	.project {
		.project-container {
			border-left: 0.1rem solid $material-theme-ref-neutral-neutral-24;

			@include break_point_laptop_sm {
				border-left: none;
			}
		}
	}
}