.text-no-wrap {
    white-space: nowrap;
}

// COLORS
$color_text: (
    primary: (light: $emerald,
        dark: $celadon,
    ),
    secondary: (light: $grey,
        dark: $white,
    ),
    terciary: (light: $emerald,
        dark: $material-theme-sys-dark-secondary,
    ),
    quaternary: (light: $emerald,
        dark: $white ),
    quinary: (light: $emerald,
        dark: $material-theme-sys-dark-outline,
    ),
    danger: (light: $fuchsia-rose,
        dark: $fuchsia-rose,
    ),
    success: (light: $emerald,
        dark: $emerald,
    ),
    normal: (light: $rich-black,
        dark: $white,
    ),
    normal_two: (
        light: $slate-gray,
        dark: $material-theme-sys-dark-outline
    )
);

@each $key, $value in $color_text {
    .text-#{$key} {
        color: map-get($value, light) !important;
    }
}

.dark-theme {
    @each $key, $value in $color_text {
        .text-#{$key} {
            color: map-get($value, dark) !important;
        }
    }
}

.dark-theme {
    .text-active {
        color: $antiflash-white;
    }

    a.text-active{
        border-bottom: 1px dotted $antiflash-white;
        text-decoration: none;
    }

    .text-gradient{
        background: linear-gradient(to right bottom, rgb(255, 255, 255) 30%, rgba(255, 255, 255, 0.38)) text;
        -webkit-box-decoration-break: clone;
        -webkit-text-fill-color: transparent;
        color: unset;
    }
}

.underline-hover {
    &:hover {
        text-decoration: underline;
    }
}